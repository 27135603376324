<template>
  <v-app app>
    <v-app-bar app color="yellow-darken-3">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-app-bar-title
        >{{ this.orgName }}

        <v-btn
          prepend-icon="mdi-registered-trademark"
          @click="this.$router.push('/product')"
          >Продажа имущества
        </v-btn>
        <v-btn
          prepend-icon="mdi-salesforce"
          @click="this.$router.push('/trades')"
          >Торги
        </v-btn>
        <v-btn
          prepend-icon="mdi-file-document-multiple"
          @click="this.$router.push('/docs')"
          >Документы
        </v-btn>
        <v-btn prepend-icon="mdi-rss" @click="this.$router.push('/feedback')"
          >Обратная связь
        </v-btn>
        <v-btn
          prepend-icon="mdi-office-building"
          @click="this.$router.push('/about')"
          >Об организации
        </v-btn>
        <v-btn v-if="this.isLogged == 1" prepend-icon="mdi-cog" to="/settings"
          >Настройки
        </v-btn>
        <v-btn
          v-if="this.isLogged == 0"
          prepend-icon="mdi-login-variant"
          @click="this.$router.push('/login')"
          >Вход
        </v-btn>
        <v-btn
          v-if="this.isLogged == 1"
          prepend-icon="mdi-logout-variant"
          @click="this.$router.push('/logout')"
          >Выход
        </v-btn>
      </v-app-bar-title>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" absolute temporary>
      <v-list>
        <v-list-item prepend-icon="mdi-registered-trademark" to="/product"
          >Продажа имущества
        </v-list-item>
        <v-list-item prepend-icon="mdi-salesforce" to="/trades"
          >Торги
        </v-list-item>
        <v-list-item prepend-icon="mdi-file-document-multiple" to="/docs"
          >Документы
        </v-list-item>
        <v-list-item prepend-icon="mdi-rss" to="/feedback"
          >Обратная связь
        </v-list-item>
        <v-list-item prepend-icon="mdi-office-building" to="/about"
          >Об организации
        </v-list-item>
        <v-list-item
          v-if="this.isLogged == 1"
          prepend-icon="mdi-cog"
          to="/settings"
          >Настройки
        </v-list-item>
        <v-list-item
          v-if="this.isLogged == 0"
          prepend-icon="mdi-login-variant"
          to="/login"
          >Вход
        </v-list-item>
        <v-list-item
          v-if="this.isLogged == 1"
          prepend-icon="mdi-logout-variant"
          to="/logout"
          >Выход
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <v-container>
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import orgService from "@/DataProviders/orgService";

export default defineComponent({
  name: "App",
  computed: {
    ...mapGetters(["isLogged"]),
  },

  data() {
    return {
      orgName: "",
      drawer: false,
    };
  },

  methods: {},
  mounted() {
    //console.log(this.isLogged);
    orgService.getOrg().then((response) => {
      this.orgName = response.data.name;
    });
  },
});
</script>
